<template>
    <div class="p-5">
        <div class="flex items-center justify-between mb-2">
            <div class="flex items-center w-3/5">
                <div class="form-feedback form-feedback-right w-full">
                    <input v-model="search" ref="search"  type="text" class="form-control" placeholder="search serial number">
                    <div v-show="!search" class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-400"></i>
                        </span>
                    </div>
                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search = ''" href="#">
                            <i class="icon-cancel-circle2 text-red-500"></i>
                        </a>
                    </div>
                    <div v-show="filters.length > 0" ref="filterSearch" class="absolute inset-y-0 my-1 left-1 flex items-center rounded-l-sm overflow-hidden bg-white">
                        <div class="bg-gray-700 h-full flex items-center text-gray-100 px-1"><i class="icon-filter3" style="font-size: 12px"></i></div>
                        <div class="border border-l-0 border-gray-400 h-full flex items-center px-1">
                            <ul class="flex space-x-1">
                                <li v-for="(filter, i) in filters" :key="i">{{filter}}
                                    <span v-show="filters.length > 1 && filters.length > i+1" class="italic">or</span>
                                </li>
                                <li class="flex items-center">
                                    <a @click="clearFilter" href="#" class="flex hover:text-red-500"><i class="icon-cross3"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div ref="filter" class="ml-2 relative">
                    <button @click="showFilter = !showFilter" type="button" class="bg-white border whitespace-nowrap relative rounded-sm h-9 w-full focus:outline-none hover:bg-gray-50">
                        <div class="relative h-full flex items-center">
                            <div class="h-full w-9 bg-gray-200 rounded-l-sm flex justify-center items-center">
                                <i class="icon-filter3"></i>
                            </div>
                            <div class="pl-3">
                                <span class="text-gray-500 pr-5">Filter</span>
                            </div>
                            <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                <i class="icon-arrow-down22"></i>
                            </div>
                        </div>
                    </button>
                    <div v-show="showFilter" class="absolute bg-white border mt-0.5 w-full">
                        <ul class="my-1">
                            <li v-for="(filter, i) in filterStatus" :key="i" class="hover:bg-gray-100">
                                <a @click="filter.status = !filter.status" href="#" class="flex items-center py-1 px-1">
                                    <div class="flex w-4">
                                        <i v-show="filter.status" class="icon-checkmark2 text-teal-500"></i>
                                    </div>
                                    <div class="ml-1" :class="{'font-medium': filter.status}">{{filter.text}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
               <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <button class="h-7 w-7 bg-gray-100 border  -mr-px"><i class="icon-grid6"></i></button>
                    <button class="h-7 w-7 bg-gray-100 border"><i class="icon-list2"></i></button>
                </div>
            </div>
        </div>
        <div class="rounded-sm overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr class="bg-blue-500 border border-blue-500">
                        <th class="py-2 pl-5 pr-2 text-blue-100" style="width: 15%">SKU#</th>
                        <th class="py-2 px-2 text-blue-100" style="width: 38%">PRODUCT NAME</th>
                        <th class="py-2 px-2 text-blue-100" style="width: 14%">SERIAL NUMBER</th>
                        <th class="py-2 px-2 text-blue-100" style="width: 12%">STATUS</th>
                        <th class="py-2 px-2 text-blue-100" style="width: 12%">DATE CREATED</th>
                        <th class="py-2 px-2 text-blue-100 text-center" style="width: 10%">ACTION</th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <tr v-for="(sn, i) in serials" :key="i" class="border">
                        <td class="pl-5 pr-2 py-2">{{sn.sku}}</td>
                        <td class="px-2 py-2">{{sn.product}}</td>
                        <td class="px-2 py-2">{{sn.serialNumber}}</td>
                        <td class="px-2 py-2">
                            <div :class="[sn.status == 'In Stock' ? 'text-green-500': 'text-red-500']" class="font-medium">{{sn.status}}</div>
                        </td>
                        <td class="px-2 py-2">{{getDate(sn.createdAt)}}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {moment} from '../../moment'
import debounce from 'lodash.debounce'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
export default {
    mixins: [BreadcrumbManager],
    data () {
        return {
            search: '',
            showFilter: false,
            filterStatus: [
                {status: false, text: 'In Stock'},
                {status: false, text: 'Out Of Stock'},
            ],
            serials: [],
            pages: '',
            searchData: ''
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted () {
        this.setButtonText('CREATE')
        this.getData()
        EventBus.$on('onClick', this.onCreate)
        document.addEventListener('click', this.closeFilter)
    },
    destroyed () {
        EventBus.$off('onClick', this.onCreate)
        document.removeEventListener('click', this.closeFilter)
    },
    computed: {
        filters () {
            let data = []
            for (let i=0; i<this.filterStatus.length; i++) {
                if (this.filterStatus[i].status == true) {
                    data.push(this.filterStatus[i].text)
                }
            }
            return data
        }
    },
    methods: {
        getData (i) {
            axios.get('/serial', {
                params: {
                    search: this.search,
                    page: i,
                    filters: this.filters
                }
            })
            .then(res => {
                this.serials = res.data.data
                this.pages = res.data.pages
            })
        },
        getDate (d) {
            return moment(d)
        },
        onCreate () {
            this.$router.push('/serial-numbers/create')
        },
        clearFilter () {
            for (let i=0; i < this.filterStatus.length; i++) {
                this.filterStatus[i].status = false
            }
        },
        closeFilter (e) {
            if(!e || !this.$refs.filter.contains(e.target)) {
                this.showFilter = false
            }
        }
    },
    watch: {
        search () {
            this.searchData()
        },
        filterStatus: {
            handler: function () {
                this.$nextTick(() => {
                    let w = this.$refs.filterSearch.clientWidth + 11
                    this.$refs.search.style.paddingLeft = w+'px'
                    this.getData()
                })
            },  
            deep: true
        }
    }
}
</script>